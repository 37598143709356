import { Link, Outlet, useMatches } from '@remix-run/react';
import { useMemo } from 'react';
import { AppButton } from '~/components/app-button.tsx';
import { useAppMode } from '~/components/dashboard/app-mode-provider.tsx';
import { ErrorActions, ErrorHeader, GeneralErrorBoundary } from '~/components/error-boundary.tsx';
import { Logo } from '~/components/logo.tsx';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '~/components/ui/navigation-menu.tsx';
import { KlaviyoForm } from '~/utils/klaviyo.js';
import { cn } from '~/utils/misc.ts';
import { useIsAuthenticated } from '~/utils/user.ts';

export type LandingHandle = {
  mainClass?: string;
};

function isLandingHandle(handle?: unknown): handle is LandingHandle {
  return !!handle && typeof handle === 'object' && 'mainClass' in handle;
}

function useLandingHandles() {
  const matches = useMatches();
  const handle = useMemo(
    () => matches.find((x) => isLandingHandle(x.handle))?.handle as LandingHandle,
    [matches],
  );

  return handle ?? ({} satisfies LandingHandle);
}

function HostOrGuestButton() {
  const isForHost = useAppMode() === 'host';

  const to = isForHost ? '/' : '/host';

  return (
    <>
      <NavigationMenuLink
        asChild
        className={cn(navigationMenuTriggerStyle(), 'xs:block hidden font-extrabold text-md')}
      >
        <Link to={to} viewTransition prefetch="intent">
          {isForHost ? 'find a place' : 'list your place'}
        </Link>
      </NavigationMenuLink>
      <NavigationMenuLink
        asChild
        className={cn(navigationMenuTriggerStyle(), 'block xs:hidden font-extrabold text-md')}
      >
        <Link to={to} viewTransition prefetch="intent">
          {isForHost ? 'find' : 'list'}
        </Link>
      </NavigationMenuLink>
    </>
  );
}

function LandingNavigationMenu() {
  return (
    <NavigationMenu>
      <NavigationMenuList className="gap-2">
        <NavigationMenuItem>
          <HostOrGuestButton />
        </NavigationMenuItem>
        <NavigationMenuItem>
          <AppButton className="rounded-full font-extrabold text-md" />
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function LandingFooter() {
  return (
    <footer className="flex items-center justify-center border-border border-t px-4 py-12 md:px-8 md:py-16">
      <nav className="flex w-full max-w-[1440px] flex-col justify-between gap-8 md:flex-row md:gap-0">
        <div className="flex flex-col gap-2">
          <Link to="/" prefetch="intent">
            <Logo />
          </Link>
          <p className="text-gray-500">©2025 All Rights Reserved.</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Join</p>
          <Link className="text-gray-500" to="/host" prefetch="intent">
            Become a host
          </Link>
          <Link className="text-gray-500" to="/" prefetch="intent">
            Become a guest
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Legal</p>
          <Link className="text-gray-500" to="/terms-of-service" prefetch="intent">
            Terms Of Service
          </Link>
          <Link className="text-gray-500" to="/privacy-policy" prefetch="intent">
            Privacy Policy
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold text-lg">Contact</p>
          <div className="flex flex-col gap-1">
            <a className="text-gray-500 hover:text-gray-700" href="mailto:hello@joinhostu.com">
              Get in touch
            </a>
            <div className="flex flex-row gap-2 pt-1">
              <a
                className="text-gray-500 hover:text-gray-700"
                href="https://www.tiktok.com/@joinhostu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokIcon className="size-6" />
              </a>
              <a
                className="text-gray-500 hover:text-gray-700"
                href="https://www.instagram.com/joinhostu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="size-6" />
              </a>
              <a
                className="text-gray-500 hover:text-gray-700"
                href="https://www.linkedin.com/company/hostu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className="size-6" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  const { mainClass } = useLandingHandles();
  return (
    <>
      <header className="fixed top-0 right-0 left-0 z-50 flex h-[var(--landing-header-height)] items-center justify-center border-border border-b bg-white px-4 md:px-8">
        <div className="flex w-full max-w-[1440px] flex-row items-center justify-between">
          <Link to="/" prefetch="intent">
            <Logo />
          </Link>
          <LandingNavigationMenu />
        </div>
      </header>
      <div className="min-h-[var(--landing-header-height)]" />
      <main className={cn('flex-1 pb-8', mainClass)}>{children}</main>
      <LandingFooter />
      <KlaviyoForm hideForDays={1} showAfterDelayMs={5000} showAfterScrolledInPixels={400} />
    </>
  );
}

export default function LandingLayout() {
  return (
    <Layout>
      <Outlet />
      <OptionalSubscribeForm />
    </Layout>
  );
}

function OptionalSubscribeForm() {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return null;
  }

  return <div className="klaviyo-form-UvXGr6" suppressHydrationWarning />;
}

export function ErrorBoundary() {
  return (
    <Layout>
      <GeneralErrorBoundary
        defaultHandler={() => (
          <>
            <ErrorHeader title="Oops!" message="Something went wrong..." />
            <ErrorActions
              left={{
                to: '/',
                text: 'Back to landing',
                variant: 'ghost-outline',
              }}
              right={<AppButton />}
            />
          </>
        )}
      />
    </Layout>
  );
}

export function LinkedInIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 256 256"
      className={className}
    >
      <path d="M216,24H40A16,16,0,0,0,24,40V216a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V40A16,16,0,0,0,216,24Zm0,192H40V40H216V216ZM96,112v64a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0Zm88,28v36a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.79-1.78A36,36,0,0,1,184,140ZM100,84A12,12,0,1,1,88,72,12,12,0,0,1,100,84Z" />
    </svg>
  );
}

export function InstagramIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 256 256"
      className={className}
    >
      <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z" />
    </svg>
  );
}

export function TikTokIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 256 256"
      className={className}
    >
      <path d="M224,72a48.05,48.05,0,0,1-48-48,8,8,0,0,0-8-8H128a8,8,0,0,0-8,8V156a20,20,0,1,1-28.57-18.08A8,8,0,0,0,96,130.69V88a8,8,0,0,0-9.4-7.88C50.91,86.48,24,119.1,24,156a76,76,0,0,0,152,0V116.29A103.25,103.25,0,0,0,224,128a8,8,0,0,0,8-8V80A8,8,0,0,0,224,72Zm-8,39.64a87.19,87.19,0,0,1-43.33-16.15A8,8,0,0,0,160,102v54a60,60,0,0,1-120,0c0-25.9,16.64-49.13,40-57.6v27.67A36,36,0,1,0,136,156V32h24.5A64.14,64.14,0,0,0,216,87.5Z" />
    </svg>
  );
}
